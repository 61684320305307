<template lang="html">
  <div class="ChatPageV2">
    <div class="ChatPageV2--Filter">
      <Tabs class="ChatPageV2--Tabs" :tabs="orderFilters" :activeOnLoad="orderFilters[0].id"
        @change="handleChangeOrderFilter" ref="TabsComponent" />
      <div class="ChatPageV2--Search">
        <SearchBar v-show="order === orderFilters[2].id" class="SearchBarV2" :placeholder="$t('filter_nickname')"
          @key-up="handleItemSelected" :loader="isLoadingSearch" />
      </div>
      <div class="ChatPageV2--ChatList" ref="chatList">
        <!-- Chat de moderador -->
        <div class="ChatPageV2--moderatorChat" :class="{ selected: observatorSelected }" @click="openChat('OBSERVER')">
          <Avatar :src="communityInfo.moderatorLogo" :allowLabs="false" />
          <span v-if="isModerator">{{ $t('role_observator') }}</span>
          <span v-else>{{ $t('role_moderator') }}</span>
        </div>
        <!-- Chat de usuarios según filtros -->
        <div class="ChatPageV2--user" v-for="chat in communityChatList" :key="chat.identifier"
          v-if="!isObservator && (order === 'recent' || order === 'all')">
          <CommunityUser :hideGamification="communityInfo.hideGamification" :class="{ selected: chat.selected }"
            @click.native="openChat(chat.identifier)" :member="chat" :allowRemind="false" />
          <Badge :count="'' + chat.notSeenCount" v-if="chat.notSeenCount > 0" />
        </div>
        <div class="ChatPageV2--user" v-for="chat in communityChatList" :key="chat.identifier"
          v-if="!isObservator && order === 'unseen' && !unseenChatIsEmpty">
          <CommunityUser :hideGamification="communityInfo.hideGamification" :class="{ selected: chat.selected }"
            @click.native="openChat(chat.identifier)" :member="chat" :allowRemind="false"
            v-if="chat.notSeenCount > 0 || (chat.notSeenCount <= 0 && chat.selected)" />
          <Badge :count="'' + chat.notSeenCount" v-if="chat.notSeenCount > 0" />
        </div>
        <div class="ChatPageV2--empty" v-if="!isObservator && order === 'unseen' && unseenChatIsEmpty">
          <h2>{{ $t('empty_unseen_chat') }}</h2>
        </div>
      </div>
    </div>
    <GradientBackLayout class="ChatPageV2--Chat" :title="$filters.translate('chat')"
      :shadowText="$filters.translate('chat').toUpperCase()">
      <Chat ref="chat" :moderatorUser="isModerator" @send-message="handleSendMessage"
        @delete-chat-message="handleDeleteChatMessage"
        :messages="observatorSelected ? communityChatMessagesObs : communityChatMessages" :members="communityChatList"
        @fetch-older-messages="onFetchOlderMessages" :isFetchingOldMessages="isFetchingOldMessages" />
    </GradientBackLayout>
  </div>
  <Teleport defer to="body">
    <div v-if="isScrollingToChat" class="modal-overlay">
      <div class="spinner-modal">
        <Spinner dark />
      </div>
    </div>
  </Teleport>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import {map, debounce } from 'lodash';
import Chat from '~/components/WatFocus/Chat.vue';
import Tabs from '~/components/Tabs';
import Badge from '~/components/Badge';
import Avatar from '~/components/Avatar';
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import CommunityUser from '@/components/CommunityUser';
import Spinner from '~/components/Spinner';
import GradientBackLayout from '@/layouts/GradientBackLayout';
import {
  COMMUNITY_FETCH_CHAT_LIST,
  COMMUNITY_FETCH_CHAT_MESSAGES,
  COMMUNITY_SEE_CHAT_MESSAGES,
  COMMUNITY_SEE_CHAT_MESSAGES_OBS,
  COMMUNITY_ADD_CHAT_MESSAGE,
  COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS,
  COMMUNITY_DELETE_CHAT_MESSAGE,
} from '@/store/community/chat.module';

export default {
  name: 'ChatPage',
  components: {
    Chat,
    Tabs,
    Badge,
    Avatar,
    GradientBackLayout,
    SearchBar,
    CommunityUser,
    Spinner
  },
  computed: {
    ...mapGetters([
      'communityId',
      'communitySelectedChat',
      'communityChatList',
      'communityChatMessages',
      'communityChatMessagesObs',
      'isClient',
      'isModerator',
      'isObservator',
      'isUser',
      'origin',
      'theme',
      'communityInfo',
      'onlineUsers',
    ]),
  },
  data() {
    return {
      orderFilters: [
        { id: 'unseen', title: 'filter_unseen' },
        { id: 'recent', title: 'filter_recent' },
        { id: 'all', title: 'filter_all' },
      ],
      photoUploadUrl: process.env.VUE_APP_API_URL + '/experienceLabs/file',
      filter: '',
      order: '',
      observatorSelected: true,
      page: 0,
      pageSize: 50,
      isLoadingChats: false,
      isLoadingSearch:false,
      currentRequest: null,
      debounceTimeout: null,
      unseenChatIsEmpty: true,
      isScrollingToChat: false,
      isFetchingOldMessages: false,
      pageForMessages: 0,
    };
  },
  methods: {
    ...mapMutations({
      changeChat: 'changeChat',
      chatOnline: 'chatOnline',
    }),
    // Usamos debounce de lodash para optimizar la búsqueda
    handleItemSelected(e, text) {
      debounce(() => this.searchBarEvent(e, text), 500)();
    },
    async searchBarEvent(e, text) {
      this.filter = text;
      this.page = 0;
      this.pageSize = 1000;
      this.isLoadingSearch = true;
      const data = {
        order: 'all',
        filter: this.filter,
        page: this.page,
        pageSize: this.pageSize,
        updateChatList: false,
      };
      const request = store.dispatch(COMMUNITY_FETCH_CHAT_LIST, data);
      this.currentRequest = request;
      await request;
      if (this.currentRequest !== request) return;
      this.isLoadingSearch = false;
      this.isLoadingChats = false;
      this.changeChat(0);
      this.fetchOnlineUsers();
    },
    async handleChangeOrderFilter(e) {
      await this.fetchChatList(e);
    },
    async pageScroll(e) {
      const chatList = this.$refs.chatList;
      if (!chatList || this.isLoadingChats) return;
      const { scrollTop, scrollHeight, clientHeight } = chatList;
      if (scrollTop + clientHeight >= scrollHeight - 5 && this.order === 'all') {
        if (this.page === 0 && this.pageSize > 10) {
          this.page = this.pageSize / 10;
          this.pageSize = 10;
        } else {
          this.page++;
        }
        await this.fetchChatList(this.order, true);
      }
    },
    async fetchChatList(e, isPageScroll = false) {
      const lastOrder = this.order;
      this.order = e;
      if (!((lastOrder === "recent" && this.order === "unseen") ||
        (lastOrder === "unseen" && this.order === "recent"))) {
        this.isLoadingChats = true;
        if (!isPageScroll && this.order === 'all' && this.page > 0) {
          this.pageSize = (this.page + 1) * this.pageSize;
          this.page = 0;
        }
        const data = {
          order: e,
          filter: this.filter,
          page: this.order === 'all' ? this.page : undefined,
          pageSize: this.order === 'all' ? this.pageSize : undefined,
          updateChatList: this.order === 'all' && lastOrder === 'all',
        };
        try {
          await store.dispatch(COMMUNITY_FETCH_CHAT_LIST, data);
          this.changeChat(0);
          this.fetchOnlineUsers();
        } finally {
          this.isLoadingChats = false;
          this.$refs.TabsComponent.handleTabDisplay(e)
        }
      }

    },
    async openChat(identifier) {
      this.observatorSelected = false;
      if (identifier === "OBSERVER") {
        // Ejecutamos en paralelo para el observador
        await Promise.all([
          this.fetchChatMessagesObs(),
          this.seeChatMessagesObs()
        ]);
        this.changeChat(-1);
        this.observatorSelected = true;
      } else {
        this.changeChat(identifier);
        await this.fetchChatMessages();
        this.seeChatMessages();
      }
      this.isFetchingOldMessages = true;
      // Reemplazamos jQuery animate por scroll nativo
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await this.$nextTick();
      this.$refs.chat.adjustMessagesScroll();
      this.isFetchingOldMessages = false;
    },
    async fetchChatMessages() {
      this.isFetchingOldMessages = true;
      this.pageForMessages = 0;
      try {
        await store.dispatch(COMMUNITY_FETCH_CHAT_MESSAGES, { userId: this.communitySelectedChat });
      } catch (error) {
        console.error(error);
      } finally {
        this.isFetchingOldMessages = false;
      }
    },
    async fetchOlderMessages() {
      if (this.communitySelectedChat < 0) return;
      this.isFetchingOldMessages = true;
      try {
        this.pageForMessages += 1;
        await store.dispatch(COMMUNITY_FETCH_CHAT_MESSAGES, {
          userId: this.communitySelectedChat,
          page: this.pageForMessages,
          limit: 20,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isFetchingOldMessages = false;
        this.seeChatMessages();
      }
    },
    async onFetchOlderMessages({ previousHeight, previousScrollTop }) {
      this.isFetchingOldMessages = true;
      try {
        await this.fetchOlderMessages();
        this.$nextTick(() => {
          const container = this.$refs.chat.$refs.messages;
          const newHeight = container.scrollHeight;
          container.scrollTop = newHeight - previousHeight + previousScrollTop;
        });
      } finally {
        this.isFetchingOldMessages = false;
      }
    },
    async fetchChatMessagesObs() {
      await store.dispatch(COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS);
    },
    seeChatMessages: async function () {
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES, {
        userId: this.communitySelectedChat,
        messageIds: _.map(this.communityChatMessages, function (o, i) { return o.identifier; })
      });
    },

    seeChatMessagesObs: async function () {
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES_OBS, {
        userId: 'observer',
        messageIds: _.map(this.communityChatMessagesObs, function (o, i) { return o.identifier })
      })
    },
    async handleSendMessage(e) {
      if (this.observatorSelected) {
        await store.dispatch(COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS, {
          message: e.message,
          image: e.image,
          file: e.file,
          video: e.video,
        });
        this.$refs.chat.adjustMessagesScroll();
        return;
      }
      await store.dispatch(COMMUNITY_ADD_CHAT_MESSAGE, {
        message: e.message,
        image: e.image,
        file: e.file,
        video: e.video,
        userId: this.communitySelectedChat,
      });
    },
    async handleDeleteChatMessage(selectedMessages) {
      await store.dispatch(COMMUNITY_DELETE_CHAT_MESSAGE, { messageIds: selectedMessages });
    },
    fetchOnlineUsers() {
      this.unseenChatIsEmpty = true; // Reiniciamos antes de evaluar
      for (const c of this.communityChatList) {
        if (c.notSeenCount > 0) this.unseenChatIsEmpty = false;
        this.chatOnline({ chat: c, value: this.onlineUsers.testersOnlineCondensed.includes(c.identifier) });
      }
    },
  },
  mounted() {
    const chatList = this.$refs.chatList;
    if (chatList) {
      chatList.addEventListener('scroll', this.pageScroll);
    }

    setInterval(() => {
      this.fetchOnlineUsers();
    }, 10000);

    setTimeout(async () => {
      if (this.$route.params.chatSelected) {
        this.order = "all";
        this.isScrollingToChat = true;
        await this.fetchChatList(this.order);
        await this.openChat(this.$route.params.chatSelected);
        this.$nextTick(() => {
          const selectedUser = document.querySelector('.ChatPageV2--ChatList .CommunityUser.selected');
          if (selectedUser) {
            selectedUser.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          this.isScrollingToChat = false;
        });
      } else {
        this.order = "unseen";
        await this.fetchChatList(this.order);
        this.changeChat(0);
        await this.openChat("OBSERVER");
      }
    }, 0);
  }
,
};
</script>

<style lang="scss">
.ChatPageV2 {
  &--Tabs {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 44px;
    padding: 0 15px !important;
    margin-top: 26px;

    .Tab {
      padding: 10px;
      margin-right: 0 !important;
      font-size: 12px;
      text-transform: uppercase;
      font-family: Roboto Condensed;
      width: 100%;
      text-align: center;

      &--Active {
        background: white;
        font-weight: bold;
        border: 1px solid #E8E8E8;
        border-bottom: none;
        box-shadow: 1px -3px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .CommunityUser {
    background: white;
    border-top: 1px solid #E0E0E0;
    cursor: pointer;
    height: 75px;

    h1 {
      font-size: 15px;
    }

    &--segmentation {
      font-size: 11px;
    }

    &:before {
      top: calc(50% + 10px);
      left: 75px;
      font-size: 14px;
      width: 36px;
    }

    &--image {
      width: 55px;
      min-width: 55px;
      height: 55px;
    }

    &.selected {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }

  &--Chat {
    .ChatVideo {
      position: relative;

      &--Messages {
        height: calc(100vh - 350px);

        @media (max-width: 767px) {
          height: calc(100vh - 340px);
        }
      }
    }
  }

  &--moderatorChat {
    padding: 9px;
    position: relative;
    min-width: 220px;
    border-top: 1px solid #E0E0E0;
    background: white;
    display: flex;

    .Avatar__Background,
    .Avatar {
      width: 43px;
      height: 43px;
    }

    span {
      font-weight: normal;
      font-size: 15px;
      letter-spacing: 0.01em;
      color: #3E3E3E;
      margin-left: 10px;
    }

    &.selected {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }

  &--user .Badge {
    position: absolute;
    top: 23px;
    right: 10px;
    font-size: 15px;
    width: 30px;
    height: 30px;
  }

  &--empty {
    background-color: #ffffff;
    color: #333;
    padding: 30px;
    border: 1px solid #ddd;
    text-align: center;
  }
}
</style>
<style scoped lang="scss">
.ChatPageV2 {
  display: grid;
  grid-template-columns: 330px auto;

  &--user {
    position: relative;
  }

  &--Filter {
    background: #EEEEEE;
  }

  &--Search {
    background: white;
    padding: 15px;

    .SearchBarV2 {
      height: 42px;
    }
  }

  &--ChatList {
    border-bottom: 1px solid #E0E0E0;
    height: calc(100vh - 212px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #e6e6e6;
    }

    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--primary-color);
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    &--Filter {
      order: 1;
    }

    &--ChatList {
      height: calc(100vh - 202px);
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


</style>
